<template>
	<el-container>
		<el-main class="tabsdf">
			<el-card shadow="never">
				<el-form ref="form" :model="form" label-width="90px">
					<el-row :gutter="10">
						<el-col :span="6" :xs="24">
							<el-form-item label="userId"><el-input v-model="form.userId" clearable placeholder="请输入userId"
									@clear="clearclick"></el-input></el-form-item>
						</el-col>
						<el-col :span="6" :xs="24">
							<el-form-item label="审核状态">
								<el-select v-model="form.auditStatus" clearable placeholder="请选择审核状态">
									<el-option :key="0" label="待审核" :value="0" />
									<el-option :key="1" label="已审核" :value="1" />
									<el-option :key="-1" label="已拒绝" :value="-1" />
								</el-select>
							</el-form-item>
						</el-col>
						<el-col :span="6" :xs="24">
							<el-form-item label="提交状态">
								<el-select v-model="form.submitStatus" clearable placeholder="请选择提交状态">
									<el-option :key="0" label="未提交" :value="0" />
									<el-option :key="1" label="已提交" :value="1" />
								</el-select>
							</el-form-item>
						</el-col>
						<el-col :span="6" :xs="24">
							<el-form-item label="打款状态">
								<el-select v-model="form.withdrawStatus" clearable placeholder="请选择打款状态">
									<el-option :key="0" label="未确认" :value="0" />
									<el-option :key="1" label="已成功" :value="1" />
									<el-option :key="-1" label="打款失败" :value="-1" />
								</el-select>
							</el-form-item>
						</el-col>
						<el-col :span="6" :xs="24">
							<el-form-item label="提款流水号"><el-input v-model="form.serialNo" clearable
									placeholder="请输入提款流水号"></el-input></el-form-item>
						</el-col>
					</el-row>
					<el-row class="top_l">
						<el-col :span="24" :xs="24">
							<el-button type="primary" @click="jouShare">搜索</el-button>
							<el-button type="primary" @click="examines" v-if="auditStatusshow === 0">批量审核</el-button>
							<el-button type="primary" @click="auditBigEcpmUserDiong = true"
								v-if="auditStatusshow === 0">大额ecpm审核</el-button>
							<el-button type="primary" @click="batchWithdrawStatus"
								v-if="withdrawStatusshow === 0">批量打款</el-button>
							<el-button type="primary" @click="buttonShowHide(1)" v-if="isOpen == 0">开启提现</el-button>
							<el-button type="danger" @click="buttonShowHide(0)" v-else>关闭提现</el-button>
						</el-col>
					</el-row>
				</el-form>
				<!-- table 表格 -->
				<el-tabs type="border-card" @tab-click="handleClick" v-model="activeName" class="tab" v-if="tabShoWl == 0">
					<el-tab-pane label="正常" name="0"></el-tab-pane>
					<el-tab-pane name="1">
						<template #label>
							<span class="custom-tabs-label">
								<span>疑似刷单</span>
								<el-badge :value="Suspectedbrushingtotals" class="item"></el-badge>
							</span>
						</template>
					</el-tab-pane>
					<el-tab-pane label="确认刷单" name="2"></el-tab-pane>
				</el-tabs>
				<el-table v-loading="serloading" :data="tableData" :header-cell-style="{ background: '#eef1f6' }"
					style="width: 100%" border stripe @selection-change="handleSelectionChange">
					<el-table-column type="selection" width="55" align="center" />
					<el-table-column prop="userId" label="userId" width="80" align="center"></el-table-column>
					<el-table-column prop="money" label="金额" width="80" align="center"></el-table-column>
					<el-table-column label="审核状态" width="100" align="center">
						<template v-slot="scope">
							<span v-if="scope.row.auditStatus == 0">待审核</span>
							<span v-else-if="scope.row.auditStatus == 1">已审核</span>
							<span v-else>已拒绝</span>
						</template>
					</el-table-column>
					<el-table-column prop="auditTime" label="审核时间" width="180" align="center"></el-table-column>
					<el-table-column prop="auditComment" label="审核描述" width="200" align="center"></el-table-column>
					<el-table-column label="刷单用户" width="100" align="center">
						<template v-slot="scope">
							<span v-if="scope.row.cheatStatus == 0">正常</span>
							<span v-else-if="scope.row.cheatStatus == 1">疑似刷单</span>
							<span v-else>确认刷单</span>
						</template>
					</el-table-column>
					<el-table-column prop="cheatMsg" label="说明" width="180" align="center"></el-table-column>
					<el-table-column label="提交状态" width="100" align="center">
						<template v-slot="scope">
							<span v-if="scope.row.submitStatus == 0">未提交</span>
							<span v-else>已提交</span>
						</template>
					</el-table-column>
					<el-table-column prop="submitTime" label="提交时间" width="180" align="center"></el-table-column>
					<el-table-column label="打款状态" width="100" align="center">
						<template v-slot="scope">
							<span v-if="scope.row.withdrawStatus == 0">未确认</span>
							<span v-else-if="scope.row.withdrawStatus == 1">已成功</span>
							<span v-else>打款失败</span>
						</template>
					</el-table-column>
					<el-table-column prop="withdrawTime" label="打款时间" width="180" align="center"></el-table-column>
					<el-table-column prop="withdrawComment" label="打款描述" width="200" align="center"></el-table-column>
					<el-table-column prop="createTime" label="创建时间" width="180" align="center"></el-table-column>
					<el-table-column prop="updateTime" label="更新时间" width="180" align="center"></el-table-column>
					<el-table-column prop="serialNo" label="流水号" width="180" align="center"></el-table-column>
					<el-table-column prop="withdrawAccount" label="提现微信账号" width="280" align="center"></el-table-column>
					<el-table-column label="操作" align="center" fixed="right" :width="width">
						<template v-slot="scope">
							<el-button size="small" type="primary" plain @click="exam(scope.row)"
								v-if="scope.row.auditStatus == 0">审核</el-button>
							<el-button size="small" type="primary" plain @click="confirm(scope.row)"
								v-if="activeName == '1' || activeName == '0'">确认刷单</el-button>
							<el-button size="small" type="primary" plain @click="siPayment(scope.row)"
								v-if="scope.row.submitStatus == 1 && scope.row.withdrawStatus == 0">
								打款
							</el-button>
							<!-- <template v-for="(item, index) in this.$TOOL.data.get('USER_INFO').sysResourcesEntities">
								<el-button
									v-if="item.parentId == twoId && item.permission.includes('push')"
									:key="index"
									size="small"
									type="primary"
									plain
									@click="update(item.permission.slice(5), scope.row, item)"
								>
									{{ item.name }}
								</el-button>
							</template> -->
						</template>
					</el-table-column>
					<template #empty>
						<el-empty :description="emptyText" :image-size="100"></el-empty>
					</template>
				</el-table>
				<!-- 分页 -->
				<div class="pagin">
					<el-pagination :small="true" v-model:currentPage="currentPage" v-model:page-size="pageSize"
						:page-sizes="[10, 20, 50, 100]" background layout="total, sizes, prev, pager, next, jumper"
						:total="+totals" @size-change="handleSizeChange" @current-change="handleCurrentChange" />
				</div>
				<!-- 批量打款 -->
				<el-dialog v-model="dialogVisiblePayment" title="打款" width="30%" :fullscreen="fullscreenshow">
					<el-form :model="ruleForm" :rules="rules" ref="addruleForm" label-width="80px" class="demo-ruleForm">
						<el-row :gutter="20">
							<el-col :span="24" :xs="24">
								<el-form-item label="打款状态" prop="payment">
									<el-select v-model="ruleForm.payment" clearable placeholder="请选择打款状态">
										<el-option :key="1" label="成功" :value="1" />
										<el-option :key="-1" label="失败" :value="-1" />
									</el-select>
								</el-form-item>
							</el-col>
						</el-row>
					</el-form>
					<template #footer>
						<span class="dialog-footer">
							<el-button type="primary" @click="submitClick('addruleForm')">确定</el-button>
							<el-button @click="dialogVisiblePayment = false">取消</el-button>
						</span>
					</template>
				</el-dialog>
				<!-- 批量审核 -->
				<el-dialog v-model="dialogVisibleexamine" title="审核" width="30%" :fullscreen="fullscreenshow">
					<el-form :model="ruleFormexa" :rules="rulesmexa" ref="addruleFormmexa" label-width="80px"
						class="demo-ruleForm">
						<el-row :gutter="20">
							<el-col :span="24" :xs="24">
								<el-form-item label="审核状态" prop="auditStatus">
									<el-select v-model="ruleFormexa.auditStatus" clearable placeholder="请选择审核状态">
										<el-option :key="1" label="通过" :value="1" />
										<el-option :key="-1" label="拒绝" :value="-1" />
									</el-select>
								</el-form-item>
							</el-col>
							<el-col :span="24" :xs="24" v-if="ruleFormexa.auditStatus == -1">
								<el-form-item label="审核状态" prop="textarea">
									<el-input v-model="ruleFormexa.textarea" :rows="4" type="textarea"
										placeholder="请输入拒绝原因" />
								</el-form-item>
							</el-col>
						</el-row>
					</el-form>
					<template #footer>
						<span class="dialog-footer">
							<el-button type="primary" @click="examineClick('addruleFormmexa')">确定</el-button>
							<el-button @click="dialogVisibleexamine = false">取消</el-button>
						</span>
					</template>
				</el-dialog>
				<!-- 确认刷单 -->
				<el-dialog v-model="confirmdialogVisibleexamine" title="确认刷单" width="30%" :fullscreen="fullscreenshow">
					<el-form :model="confirmruleFormexa" ref="confirmaddruleFormmexa" label-width="80px"
						class="demo-ruleForm">
						<el-row :gutter="20">
							<el-col :span="24" :xs="24">
								<el-form-item label="说明" prop="textarea">
									<el-input v-model="confirmruleFormexa.textarea" :rows="4" type="textarea"
										placeholder="请输入说明" />
								</el-form-item>
							</el-col>
						</el-row>
					</el-form>
					<template #footer>
						<span class="dialog-footer">
							<el-button type="primary" @click="confirmexamineClick()">确定</el-button>
							<el-button @click="confirmdialogVisibleexamine = false">取消</el-button>
						</span>
					</template>
				</el-dialog>
				<!-- 大额ECPM审核 -->
				<el-dialog v-model="auditBigEcpmUserDiong" title="大额ECPM审核" width="30%" :fullscreen="fullscreenshow">
					<el-form ref="form" :rules="auditBrulesmexa" :model="auditBigEcpmUserform" label-width="70px">
						<el-row :gutter="10">
							<el-col :span="13" :xs="24">
								<el-form-item label="日期" prop="valueData">
									<el-date-picker v-model="auditBigEcpmUserform.valueData" type="date"
										value-format="YYYY-MM-DD" placeholder="请选择日期" />
								</el-form-item>
							</el-col>
							<el-col :span="11" :xs="24">
								<el-form-item label="ECPM" prop="ecpm"><el-input v-model="auditBigEcpmUserform.ecpm"
										clearable placeholder="请输入ECPM"></el-input></el-form-item>
							</el-col>
						</el-row>
						<el-row :gutter="10" v-if="textareadata != ''">
							<el-col :span="24" :xs="24">
								<el-form-item label="返回内容">
									<el-tag>{{ textareadata }}</el-tag>
								</el-form-item>
							</el-col>
						</el-row>
					</el-form>
					<template #footer>
						<span class="dialog-footer">
							<el-button type="primary" @click="auditBigEcpmUserClick()">确定</el-button>
							<el-button @click="auditBigEcpmUserDiong = false">取消</el-button>
						</span>
					</template>
				</el-dialog>
			</el-card>
		</el-main>
	</el-container>
</template>

<script>
export default {
	name: 'tradeWithdrawDetail',
	data() {
		return {
			width: 180,
			confirmdialogVisibleexamine: false,
			tabShoWl: null,
			activeName: '',
			dialogVisibleexamine: false,
			dialogVisiblePayment: false,
			multipleSelection: [],
			fullscreenshow: false,
			emptyText: '暂无数据',
			currentPage: 1,
			pageSize: 10,
			tableData: [],
			totals: null,
			serloading: false,
			productId: '',
			isOpen: '',
			form: {
				userId: '',
				auditStatus: '',
				submitStatus: '',
				withdrawStatus: '',
				serialNo: ''
			},
			auditStatusshow: '',
			withdrawStatusshow: '',
			ruleForm: {
				payment: ''
			},
			rules: {
				payment: [{ required: true, message: '请选择打款状态', trigger: 'change' }]
			},
			ruleFormexa: {
				auditStatus: '',
				textarea: ''
			},
			rulesmexa: {
				auditStatus: [{ required: true, message: '请选择审核状态', trigger: 'change' }],
				textarea: [{ required: true, message: '请输入拒绝原因', trigger: 'blur' }]
			},
			twoId: null,
			confirmruleFormexa: {
				textarea: ''
			},
			serialNo: '',
			Suspectedbrushingtotals: 0,
			auditBigEcpmUserDiong: false,
			auditBigEcpmUserform: {
				valueData: '',
				ecpm: ''
			},
			textareadata: '',
			auditBrulesmexa: {
				valueData: [{ required: true, message: '请选择日期', trigger: 'blur' }],
				ecpm: [{ required: true, message: '请输入ecpm', trigger: 'blur' }]
			}
		};
	},
	created() {
		if (document.body.clientWidth < 768) {
			this.fullscreenshow = true;
			this.width = 'auto';
		} else {
			this.fullscreenshow = false;
			this.width = 180;
		}
		const start = new Date();
		start.setTime(start.getTime() - 3600 * 1000 * 24 * 1);
		this.auditBigEcpmUserform.valueData = this.$moment(start).format('YYYY-MM-DD');

		// 二级目录ID
		this.twoId = this.$route.query.Id;
		this.productId = this.$TOOL.data.get('DATA_SELECTEED');
		this.form.userId = this.$TOOL.data.get('USERID');
		// 获取提现列表
		this.tradeWithdrawDetail();
		// 查询开关状态
		this.isOpenClick();
	},
	watch: {
		'$store.state.dataSelected'(newval) {
			if (this.productId != newval) {
				this.productId = newval;
				// 获取提现列表
				this.tradeWithdrawDetail();
				// 查询开关状态
				this.isOpenClick();
			}
		},
		'$store.state.userid'(newval) {
			if (this.form.userId != newval) {
				this.form.userId = newval;
				// 获取提现列表
				this.tradeWithdrawDetail();
				// 查询开关状态
				this.isOpenClick();
			}
		}
	},
	methods: {
		// 获取提现列表
		tradeWithdrawDetail() {
			this.serloading = true;
			this.$HTTP.post(
				'tradeWithdrawDetail/query',
				{
					productId: this.productId,
					pageNumber: this.currentPage,
					pageSize: this.pageSize,
					userId: this.form.userId,
					auditStatus: this.form.auditStatus,
					submitStatus: this.form.submitStatus,
					withdrawStatus: this.form.withdrawStatus,
					serialNo: this.form.serialNo,
					cheatStatus: this.activeName
				},
				response => {
					if (response.status >= 200 && response.status < 300) {
						if (response.data.code == 0) {
							this.tableData = response.data.data.records;
							this.totals = response.data.data.total;
							this.serloading = false;
						} else {
							this.$message.error(response.data.msg);
						}
					} else {
						console.log(response.message);
					}
				}
			);
		},
		// table 切换
		handleClick(event) {
			this.activeName = event.index;
			this.tradeWithdrawDetail();
		},
		// 搜索
		jouShare() {
			this.currentPage = 1;

			if (this.form.auditStatus === 0) {
				this.tabShoWl = this.form.auditStatus;
				this.activeName = '0';
				// 获取疑似刷单条数
				this.Suspectedbrushing();
			} else {
				this.tabShoWl = null;
				this.activeName = '';
			}
			this.auditStatusshow = this.form.auditStatus;
			this.withdrawStatusshow = this.form.withdrawStatus;
			// 获取提现列表
			this.tradeWithdrawDetail();
		},
		// 获取疑似刷单条数
		Suspectedbrushing() {
			this.$HTTP.post(
				'tradeWithdrawDetail/query',
				{
					productId: this.productId,
					pageNumber: this.currentPage,
					pageSize: this.pageSize,
					userId: this.form.userId,
					auditStatus: this.form.auditStatus,
					submitStatus: this.form.submitStatus,
					withdrawStatus: this.form.withdrawStatus,
					serialNo: this.form.serialNo,
					cheatStatus: 1
				},
				response => {
					if (response.status >= 200 && response.status < 300) {
						if (response.data.code == 0) {
							this.Suspectedbrushingtotals = response.data.data.total;
						} else {
							this.$message.error(response.data.msg);
						}
					} else {
						console.log(response.message);
					}
				}
			);
		},
		// 开启/关闭 提现
		buttonShowHide(e) {
			this.$confirm('此操作将更改提现, 是否继续?', '提示', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				type: 'warning'
			}).then(() => {
				this.$HTTP.post(
					'tradeWithdrawDetail/updateWithdrawIsOpen',
					{
						isOpen: e,
						productId: this.productId,
					},
					response => {
						if (response.status >= 200 && response.status < 300) {
							if (response.data.code == 0) {
								// 查询开关状态
								this.isOpenClick();
							} else {
								this.$message.error(response.data.msg);
							}
						} else {
							console.log(response.message);
						}
					}
				);
			}).catch(() => {
				this.$message({
					type: 'info',
					message: '已取消更改'
				});
			});


		},
		isOpenClick() {
			// 查询开关状态
			this.$HTTP.post('tradeWithdrawDetail/getWithdrawIsOpen', {
				productId: this.productId,
			}, response => {
				if (response.status >= 200 && response.status < 300) {
					if (response.data.code == 0) {
						this.isOpen = response.data.data.isOpen;
					} else {
						this.$message.error(response.data.msg);
					}
				} else {
					console.log(response.message);
				}
			});
		},
		// 多选
		handleSelectionChange(val) {
			this.multipleSelection = [];
			for (var i = 0; i < val.length; i++) {
				this.multipleSelection.push(val[i].serialNo);
			}
		},
		// 批量打款
		batchWithdrawStatus() {
			if (this.multipleSelection == '') {
				return this.$message.error('请选择打款内容');
			}

			this.dialogVisiblePayment = true;
		},
		// 打款
		siPayment(row) {
			this.multipleSelection = [];
			this.dialogVisiblePayment = true;
			this.multipleSelection.push(row.serialNo);
		},
		// 确定打款
		submitClick(name) {
			this.$refs[name].validate(valid => {
				if (valid) {
					this.$HTTP.post(
						'tradeWithdrawDetail/batchWithdrawStatus',
						{
							productId: this.productId,
							withdrawStatus: this.ruleForm.payment,
							serialNos: this.multipleSelection.join(',')
						},
						response => {
							if (response.status >= 200 && response.status < 300) {
								if (response.data.code == 0) {
									this.$message.success(response.data.msg);
									// 获取提现列表
									this.tradeWithdrawDetail();
								} else {
									this.$message.error(response.data.msg);
								}
							} else {
								console.log(response.message);
							}
						}
					);
				} else {
					return false;
				}
			});
		},
		// 大额ecpm审核
		auditBigEcpmUserClick() {
			this.$HTTP.post(
				'tradeWithdrawDetail/auditBigEcpmUser',
				{
					productId: this.productId,
					day: this.auditBigEcpmUserform.valueData,
					ecpm: this.auditBigEcpmUserform.ecpm
				},
				response => {
					if (response.status >= 200 && response.status < 300) {
						if (response.data.code == 0) {
							this.$message.success(response.data.msg);
							this.textareadata = response.data.data;
						} else {
							this.$message.error(response.data.msg);
						}
					} else {
						console.log(response.message);
					}
				}
			);
		},
		// 批量审核
		examines() {
			if (this.multipleSelection == '') {
				return this.$message.error('请选择审核内容');
			}

			this.dialogVisibleexamine = true;
		},
		// 审核
		exam(row) {
			this.multipleSelection = [];
			this.dialogVisibleexamine = true;
			this.multipleSelection.push(row.serialNo);
		},
		confirm(row) {
			this.serialNo = row.serialNo;
			this.confirmdialogVisibleexamine = true;
		},
		confirmexamineClick() {
			this.$HTTP.post(
				'tradeWithdrawDetail/confirmCheat',
				{
					serialNo: this.serialNo,
					cheatMsg: this.confirmruleFormexa.textarea
				},
				response => {
					if (response.status >= 200 && response.status < 300) {
						if (response.data.code == 0) {
							this.$message.success(response.data.msg);
							this.confirmdialogVisibleexamine = false;
							// 获取提现列表
							this.tradeWithdrawDetail();
							// 获取疑似刷单条数
							this.Suspectedbrushing();
						} else {
							this.$message.error(response.data.msg);
						}
					} else {
						console.log(response.message);
					}
				}
			);
		},
		// 确认审核
		examineClick(name) {
			this.$refs[name].validate(valid => {
				if (valid) {
					this.$HTTP.post(
						'tradeWithdrawDetail/audit',
						{
							productId: this.productId,
							serialNos: this.multipleSelection.join(','),
							auditStatus: this.ruleFormexa.auditStatus,
							auditComment: this.ruleFormexa.textarea
						},
						response => {
							if (response.status >= 200 && response.status < 300) {
								if (response.data.code == 0) {
									this.$message.success(response.data.msg);
									// 获取提现列表
									this.tradeWithdrawDetail();
									// 获取疑似刷单条数
									this.Suspectedbrushing();
									this.dialogVisibleexamine = false;
								} else {
									this.$message.error(response.data.msg);
								}
							} else {
								console.log(response.message);
							}
						}
					);
				} else {
					return false;
				}
			});
		},
		// 权限button按钮方法
		update(methodsNums, row, item) {
			//触发这个参数的方法
			this[methodsNums](row, item);
		},
		// 关联跳转
		push(row, item) {
			this.$store.commit('setuserid', row.userId);
			this.$TOOL.data.set('USERID', row.userId);
			this.$router.push({
				path: item.url.split('?')[0],
				query: {
					Id: item.url.split('=')[1]
				}
			});
		},
		// input 清空
		clearclick() {
			this.$TOOL.data.remove('USERID');
		},
		// 分页
		handleSizeChange(val) {
			this.currentPage = 1;
			this.pageSize = val;
			// 获取提现列表
			this.tradeWithdrawDetail();
		},
		handleCurrentChange(val) {
			this.currentPage = val;
			// 获取提现列表
			this.tradeWithdrawDetail();
		}
	}
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss"></style>
